import React, { useState } from "react";
import { Loader, Label } from "semantic-ui-react";
import PropTypes from "prop-types";

const propTypes = {
  type: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

const BatteryImage = ({
  type,
  height,
  width,
  float,
  transform,
  margin,
  view,
}) => {
  const [loaded, setLoaded] = useState(false);
  console.log(type)
  const styles = (type) => {
    if (view === "sharingpointView") {
      if (type === "AES eBike Akku 2.0 48V") {
        return {
          margin: margin ? margin : "-45px 0px -55px 0px",
          padding: "2px 30px 35px 20px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Niu 60V") {
        return {
          margin: margin ? margin : "-20px -20px -25px -20px",
          padding: "3px 35px 3px 35px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Okai B") {
        return {
          margin: margin ? margin : "-50px -20px -55px -20px",
          padding: "3px 30px 3px 30px",
          transform: "rotate(270deg)",
        };
      }
      if (type === "Okai A") {
        return {
          margin: margin ? margin : "-30px -20px -30px -20px",
          padding: "0px 25px 0px 25px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Segway") {
        return {
          margin: margin ? margin : "-30px -20px -30px -20px",
          padding: "0px 20px 0px 20px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Greenpack") {
        return {
          margin: margin ? margin : "-40px -20px -40px -20px",
          padding: "3px 20px 3px 20px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Kumpan") {
        return {
          margin: margin ? margin : "-30px -20px -30px -20px",
          padding: "3px 20px 3px 20px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Torrot") {
        return {
          margin: margin ? margin : "-30px -20px -30px -20px",
          padding: "3px 40px 3px 40px",
          transform: "rotate(90deg)",
        };
      }
      if (type === "Universal Charger") {
        return {
          margin: margin ? margin : "10px 0px 0px 0px",
          padding: "0px 25px 0px 25px",
        };
      } 
      if (type === "YMC Neos") {
        return {
          margin: margin ? margin : "-30px -20px -30px -20px",
          padding: "3px 40px 3px 40px",
          transform: "rotate(90deg)",
        };
      } if (type === "Swobbee ONE") {
        return {
//          margin: margin ? margin : "-30px -20px -30px -20px",
//          padding: "3px 40px 3px 40px",
//          transform: "rotate(0deg)",
        };
      } else {
        return {
          margin: margin ? margin : "-30px 20px -30px 0px",
          padding: "3px 40px 3px 0px",
          transform: "rotate(90deg)",
        };
      }
    } else if (view === "swapView") {
      return { margin: margin ? margin : "0 0 0 0", padding: 0 };
    } else if (view === "batteryView") {
      if (type === "Greenpack") {
        return {
          margin: margin ? margin : "0 0 0 0",
          padding: 0,
          transform: "rotate(90deg)",
        };
      } else {
        return { margin: margin ? margin : "0 0 0 0", padding: 0 };
      }
    } else {
      return { margin: margin ? margin : "0 0 0 0", padding: 0 };
    }
  };

  return (
    <>
      {type && (
        <img
          alt=""
          style={{
            height,
            width,
            float,
            display: loaded ? "block" : "none",
            ...styles(type),
          }}
          onLoad={() => {
            setLoaded(true);
          }}
          src={`https://swobbee-frontend-assets.s3.eu-central-1.amazonaws.com/${type
            .replaceAll("_", " ")
            .toLowerCase()}.png`}
        />
      )}
      {!loaded && (
        <div
          style={{
            margin: "auto",
            height,
            width,
          }}
        >
          <Loader
            active
            inline
          />
        </div>
      )}
      {!type && <Label content="No image available" />}
    </>
  );
};

BatteryImage.propTypes = propTypes;

export default BatteryImage;
